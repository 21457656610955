import tupeloApi from './helpers';
import Client from './Client';

const getClients = async (): Promise<Client[]> => {
  const result = await tupeloApi.get(`/clients/${process.env.REACT_APP_AGENCY_ID}`);
  return result.data as Client[];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getClients,
};
