import tupeloApi from './helpers';
import Scene from './Scene';

const getScenes = async (): Promise<Scene[]> => {
  const result = await tupeloApi.get(`/scenes/${process.env.REACT_APP_AGENCY_ID}`);
  return result.data as Scene[];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getScenes,
};
