import tupeloApi from './helpers';
import Asset from './Asset';

const getAssets = async (sceneId: string): Promise<Asset[]> => {
  const result = await tupeloApi.get(`/assets/${sceneId}`);
  return result.data as Asset[];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAssets,
};
