import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';

import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import createTable from '../../helpers/table';
import Table from '../Table/Table';
import {Secure} from '../HOC/Secure';
import Journey from '../../api/Journey';
import apiJourneys from '../../api/apiJourneys';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

function Audiences() {
  const [journeys, setJourneys] = useState([] as Journey[]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<Journey>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        accessorFn: (row) => row.name.substring(0, 25),
        size: 70,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        accessorFn: (row) => row.email.substring(0, 25),
        size: 70,
      },
      {
        accessorKey: 'scheduled',
        header: 'Date',
        accessorFn: (row) => dayjs(row.scheduled).format('MMM D YYYY'),
      },
      {
        accessorKey: 'sceneName',
        header: 'Scene',
        accessorFn: (row) => row.sceneName.substring(0, 25),
        size: 70,
      },
      {
        accessorKey: 'testName',
        header: 'Test',
        accessorFn: (row) => row.testName.substring(0, 25),
        size: 70,
      },
      {
        accessorKey: 'code',
        header: 'Code',
        accessorFn: (row) => row.code?.toString().padStart(5, '0')
      },
      {
        header: 'Journey Report',
        cell: (cell) => <Link to={`/reports/${cell.row.original.id}`}>Report</Link>,
      },
    ],
    [],
  );

  useEffect(() => {
    getJourneys();
  }, []);

  function getJourneys() {
    apiJourneys.getJourneys().then((journeys) => setJourneys(journeys));
  }

  const table = createTable(columns, journeys, setSorting, sorting);

  const getCellClass = (cellId: string) => {
    let className = '';
    if (cellId.indexOf('id') > -1) {
      className = 'text-start';
    }
    return className;
  };

  return (
    <>
      <div className="row justify-content-between">
        <div className="col text-end">
          <Button
            variant="primary"
            href="/journeys"
          >
            New Journey
          </Button>
        </div>
      </div>
      <div className="row mt-3">
        {journeys.length > 0 && <Table getCellClass={getCellClass} table={table} />}
        {journeys.length === 0 && <h4>No journeys yet</h4>}
      </div>
    </>
  );
}

export default Secure(Audiences);
