import {Column, Row, Table, flexRender} from '@tanstack/react-table';
import React from 'react';
import './Table.scss';

function Filter({
  column,
}: {
  column: Column<any, any>
}) {
  return (
    <input
      type="text"
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      className="w-36 border"
    />
  );
}

function TableComponent(props:
  {
    getCellClass: (id: string) => string;
    getRowClass?: (row: Row<any>) => string;
    table: Table<any>;
    headerFilters?: boolean;
}) {
  const {getCellClass, getRowClass, table} = props;

  return (
    <div style={{maxHeight: '45vh', overflowY: 'scroll'}}>
      <table width="100%" className="mb-3">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th key={header.id} className={getCellClass(header.id)}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      <div>
                        {props.headerFilters && index === 1 ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows
            .map((row) => (
              <tr className={getRowClass ? getRowClass(row) : ''} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={getCellClass(cell.id)}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
