import {ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel,
  getPaginationRowModel, getSortedRowModel, useReactTable,
  type RowSelectionState, OnChangeFn} from '@tanstack/react-table';
import React from 'react';

const createTable = (
  columns: ColumnDef<any>[],
  data: any[],
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>,
  sorting: SortingState,
  enableRowSelection?: boolean,
  rowSelection?: RowSelectionState,
  onRowSelectionChange?: OnChangeFn<RowSelectionState>,
// eslint-disable-next-line react-hooks/rules-of-hooks
) => useReactTable({
  data,
  columns,
  enableRowSelection: enableRowSelection || false,
  state: {
    rowSelection,
    sorting,
    pagination: {
      pageIndex: 0,
      pageSize: data?.length || 0,
    }
  },
  onRowSelectionChange,
  onSortingChange: setSorting,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getSortedRowModel: getSortedRowModel(),
});

export default createTable;
