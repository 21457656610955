import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import Journey from './components/Journey/Journey';
import Login from "./components/Login/Login";
import Main from "./components/Main/Main";
import TestBuilder from './components/TestBuilder/TestBuilder';
import Reports from "./components/Reports/Reports";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/journeys',
    element: <Journey />
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/tests/:id?',
    element: <TestBuilder />
  },
  {
    path: '/reports/:id?',
    element: <Reports />
  },
]);

export default router;
