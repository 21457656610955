import tupeloApi from './helpers';
import Journey from './Journey';

const createJourney = async (journey: Journey): Promise<Journey> => {
  const result = await tupeloApi.post(`/journey`, journey);
  return result.data as Journey;
};

const getJourneys = async (): Promise<Journey[]> => {
  const result = await tupeloApi.get(`/journeys/${process.env.REACT_APP_AGENCY_ID}`);
  return result.data as Journey[];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createJourney,
  getJourneys,
};
