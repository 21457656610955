import tupeloApi from './helpers';
import Report from '../components/Reports/Report';
import ActionLog from '../components/Reports/ActionLog';
import SentimentLog from '../components/Reports/SentimentLog';
import {cloneDeep, compact, find, map} from 'lodash';
import ActionType from './ActionType';
import Scene from './Scene';
import apiScenes from './apiScenes';

const getReport = async (journeyId: string): Promise<Report> => {
  const result = await tupeloApi.get(`/reports/${journeyId}`);
  const scenes = await apiScenes.getScenes();

  const rawReport = result.data as any;
 
  const sentimentLogs = compact(processSentimentLogs(rawReport.reports));

  const report = {
    journey: rawReport.journey,
    test: rawReport.test,
    sentimentLogs,
    scene: findScene(scenes as Scene[], rawReport.test.sceneId),
    actionLogs: processActionLogs(rawReport.actionLogs, sentimentLogs),
  }

  console.log(report);

  return report;
};

// THIS IS A TERRIBLE WAY TO DO THIS, BUT WE HAVE TO FOR NOW>>> FIX LATER
const findScene = (scenes: Scene[], id: string): Scene | undefined => {
  return find(scenes, (scene) => scene.id === id);
} 

const processActionLogs = (actionLogs: ActionLog[], sentimentLogs: SentimentLog[]): ActionLog[] => {
  return map(actionLogs, (actionLog) => processActionLog(actionLog, sentimentLogs));
}

const processActionLog = (actionLog: ActionLog, sentimentLogs: SentimentLog[]): ActionLog => {
  const processedLog = cloneDeep(actionLog);

  if (actionLog.type === ActionType.Comment) {
    processedLog.sentimentLog = findSentimentLog(actionLog, sentimentLogs);
  }

  processedLog.x = Number(actionLog.x);
  processedLog.y = Number(actionLog.y);
  processedLog.z = Number(actionLog.z);
  processedLog.timestamp = new Date(actionLog.timestamp);
  return processedLog;
}

const processSentimentLogs = (reports: any[]): (SentimentLog | undefined)[]  => {
  return map(reports, (report) => processReport(report));
}

const processReport = (report: any): SentimentLog | undefined => {
  let sentimentLog: SentimentLog | undefined;
  console.log('report: ', report);

  if (report.sentiment) {
    const sentiment = {
      value: report.sentiment.Sentiment,
      ...report.sentiment.SentimentScore,
    };
    
    sentimentLog = {
      id: report.digestion.id,
      sentiment,
      transcript: report.transcript.results.transcripts[0].transcript,
      created: new Date(report.digestion.created),
    }
  }

  return sentimentLog;
}

const findSentimentLog = (actionLog: ActionLog, sentimentLogs: SentimentLog[]): SentimentLog | undefined => {
  return find(sentimentLogs, (sentimentLog) => sentimentLog.created === actionLog.timestamp);

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getReport,
};
