import axios from 'axios';

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    authorization: '',
  },
};

const tupeloApi = axios.create(axiosConfig);

export default tupeloApi;
