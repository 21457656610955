import React, {FC} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';
import {HeaderProps} from "../../interfaces/HeaderProps";

const RenderContext: FC<HeaderProps> = ({children}) => {
  const {isCognitoAuthenticated} = useCognitoAuth(); // Cognito Auth context
  return isCognitoAuthenticated ? ( // Check if logged in
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RenderContext;
