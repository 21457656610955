import React, { ReactNode, useContext, useEffect, useState } from 'react';
import Asset from '../../api/Asset';
import Client from '../../api/Client';
import Scene from '../../api/Scene';
import Test from '../../api/Test';
import {useNavigate, useParams} from 'react-router-dom';
import apiTests from '../../api/apiTests';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import apiScenes from '../../api/apiScenes';
import apiClients from '../../api/apiClients';
import TestStep from '../../api/TestStep';
import apiAssets from '../../api/apiAssets';
import { ToastContext } from '../../App';
import toast from '../../helpers/toast';
import ActionType from '../../api/ActionType';

function TestBuilder() {
    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const params = useParams();
    const [availableAssets, setAvailableAssets] = useState<Asset[]>([])
    const [availableClients, setAvailableClients] = useState<Client[]>([] as Client[]);
    const [availableScenes, setAvailableScenes] = useState<Scene[]>([] as Scene[]);
    const [showErrorsModal, setShowErrorsModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [test, setTest] = useState<Test | null>(null);

    useEffect(() => {
        apiClients.getClients().then((clients) => setAvailableClients(clients));

        apiScenes.getScenes().then((scenes) => {
            setAvailableScenes(scenes);

            if (params.id) {
                apiTests.getTests().then((tests) => {
                    const test = tests.filter((test) => test.id === params.id)[0];
                    setTest(test);
                    apiAssets.getAssets(test.sceneId).then((assets) => setAvailableAssets(assets));
                })
            } else {
                setTest(
                    {
                        clientId: '',
                        clientName: '',
                        sceneId: '',
                        sceneName: '',
                        name: '',
                        created: new Date(),
                        steps: [],
                    }
                );
            }
        });
    }, [params.id]);

    if (!test) {
        return <div>Loading...</div>
    }

    const addAction = () => {
        const newStep = {
            assetId: '',
            assetName: '',
            action: '',
        }
        setTest({ ...test, steps: [...test.steps, newStep]});
        setUnsavedChanges(true);
    }

    const removeAction = (idx: number) => {
        const newSteps = test.steps.filter((step: TestStep) => test.steps.indexOf(step) !== idx);
        setTest({ ...test, steps: newSteps });
        setUnsavedChanges(true);
    }

    const renderActions = () => (
        <table className="table px-6" style={{maxWidth: '90%'}}>
            <thead style={{ zIndex: 2 }}>
                <tr>
                    <th scope="col">Step #</th>
                    <th scope="col">Action</th>
                    <th scope="col">Asset</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                {test.steps.map((step: TestStep, idx: number) => renderTestActionRow(step, idx))}
            </tbody>
        </table>
    );

    const renderTestActionRow = (step: TestStep, idx: number) => (
        <tr>
            <th scope="row">{idx + 1}</th>
            <td>
                <Dropdown style={{width: 170}} id={`selectAction${idx}`} aria-label={`Action select ${idx}`}>
                    <Dropdown.Toggle style={{width: 170}} split={!!step.action}>
                        {step.action ? `${step.action} ` : 'Select an Action '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant='dark'>
                        {renderActionTypes(idx)}
                    </Dropdown.Menu> 
                </Dropdown>                            
            </td>
            <td>
                <Dropdown style={{width: 170}} id={`selectAsset${idx}`} aria-label={`Asset select ${idx}`}>
                    <Dropdown.Toggle style={{width: 170}} split={!!step.assetId}>
                        {step.assetName ? `${step.assetName} ` : 'Select an Asset '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant='dark'>
                        {renderAvailableAssets(idx)}
                    </Dropdown.Menu> 
                </Dropdown>                            
            </td>
            <td>
                <Button
                    id={`deleteAction${idx}`}
                    aria-label={`delete action ${idx}`}
                    onClick={() => removeAction(idx)}
                    variant="secondary"
                >
                    <i className="bi bi-trash" />
                </Button>                            
            </td>
        </tr>
    );

    const updateName = (name: string) => {
        setTest({ ...test, name });
        setUnsavedChanges(true);
    }

    const updateStepAction = (idx: number, action: string) => {
        const newSteps = test.steps.map((step, index) => index !== idx ? step : { ...step, action });
        setTest({ ...test, steps: newSteps });
        setUnsavedChanges(true);
    }

    const updateStepAsset = (idx: number, asset: Asset) => {
        const newSteps = test.steps.map((step, index) => index !== idx ? step : { ...step, assetId: asset.id, assetName: asset.assetName });
        setTest({ ...test, steps: newSteps });
        setUnsavedChanges(true);
    }

    const renderActionTypes = (idx: number) => {
        const actionTypeValues = Object.values(ActionType);
        
        return actionTypeValues.map((action: string) => <Dropdown.Item key={`${idx}-${action}`} onClick={() => updateStepAction(idx, action)} value={action}>{action}</Dropdown.Item>)
    }

    const renderAvailableAssets = (idx: number) => {
        return availableAssets.map((asset: Asset) => <Dropdown.Item key={`step-${idx}`} onClick={() => updateStepAsset(idx, asset)} value={asset.id}>{asset.assetName}</Dropdown.Item>)
    }

    const renderClientOptions = () => {
        return availableClients.map((client: Client) => <Dropdown.Item key={client.id} value={client.id} onClick={() => updateClient(client)}>{client.name}</Dropdown.Item>)
    }

    const updateClient = (client: Client) => {
        setTest({ ...test, clientId: client.id || '', clientName: client.name });
        setUnsavedChanges(true);
    }

    const renderSceneOptions = () => {
        return availableScenes.map((scene: Scene) => <Dropdown.Item key={scene.id} value={scene.id} onClick={() => updateScene(scene)}>{scene.name}</Dropdown.Item>)
    }

    const save = () => {
        if (testErrors().length > 0) {
            setShowErrorsModal(true);
            return;
        }

        if (params.id) {
            apiTests.updateTest(test).then(() => {
                toast.setAlert(toastContext, 'success');
                setTimeout(() => navigate('/'), 1500);
            }).catch(() => toast.setAlert(toastContext, 'failure'));
        } else {
            apiTests.createTest(test).then(() => {
                toast.setAlert(toastContext, 'success');
                setTimeout(() => navigate('/'), 1500);
            }).catch(() => toast.setAlert(toastContext, 'failure'));
        }
    }

    const updateScene = (scene: Scene) => {
        const newSteps = test.steps.map((step) => ({ ...step, assetId: '', assetName: '' }));
        setTest({ ...test, sceneId: scene.id, sceneName: scene.name, steps: newSteps });
        setUnsavedChanges(true);
        apiAssets.getAssets(scene.id).then((assets) => setAvailableAssets(assets));
    }

    const testErrors = (): string[] => {
        const errors = [];
        for (let idx = 0; idx < test.steps.length; idx += 1) {
            const step = test.steps[idx];
            if (!step.action || !step.assetId) {
                errors.push(`Select an action and an asset for step ${idx + 1}`);
            }
        }

        if (test.steps.length === 0) {
            errors.push('Add at least one step to this test');
        }

        if (!test.clientId) {
            errors.push('Select a client');
        }

        if (!test.name) {
            errors.push('Name this test');
        }

        if (!test.sceneId) {
            errors.push('Select a scene');
        }

        return errors;
    }

    const errorsModal = (): ReactNode => (
        <Modal show={showErrorsModal} onHide={() => setShowErrorsModal(false)} style={{fontSize: 24}}>
          <Modal.Header closeButton>
            <Modal.Title>Test Errors</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Correct the following errors:</div>
            <div>
                <ul>
                    {testErrors().map((error) => <li>{error}</li>)}
                </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" size="lg" onClick={() => { setShowErrorsModal(false) }}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    const unsavedChangesModal = (): ReactNode => (
        <Modal show={showUnsavedChangesModal} onHide={() => setShowUnsavedChangesModal(false)} style={{fontSize: 24}}>
          <Modal.Header closeButton>
            <Modal.Title>You have unsaved changes to this test.</Modal.Title>
          </Modal.Header>
          <Modal.Body>Save changes?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="lg" onClick={() => setShowUnsavedChangesModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" size="lg" onClick={() => cancel(true)}>
              No
            </Button>
            <Button variant="primary" size="lg" onClick={() => { setShowUnsavedChangesModal(false); save(); }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      );

    const cancel = (overrideUnsaved?: boolean) => {
        if (unsavedChanges && !overrideUnsaved) {
            setShowUnsavedChangesModal(true);
            return;
        }

        navigate('/');
    }

    return (
        <div>
            {errorsModal()}
            {unsavedChangesModal()}
            <Button onClick={() => cancel()} variant="secondary" size="lg" className='back-button'>Back</Button>
            <div className="d-flex justify-content-between mb-4">
                <h3>Test Builder</h3>
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="txtTestName"
                    className="col-form-label col-1"
                    >
                    Test Name
                </label>
                <input
                    type="text"
                    className="form-control w-25"
                    id="txtTestName"
                    name="id"
                    value={test.name}
                    onChange={(evt) => updateName(evt.currentTarget.value)}
                />
                <label
                    htmlFor="selectClient"
                    className="col-form-label col-1"
                >
                    Client
                </label>
                <Dropdown id="selectClient" className='w-25'>
                    <Dropdown.Toggle split={!!test.clientId}>
                        {test.clientName ? `${test.clientName} ` : 'Select a Client '}
                    </Dropdown.Toggle>
                   <Dropdown.Menu variant='dark'>
                        {renderClientOptions()}
                    </Dropdown.Menu> 
                </Dropdown>
                <label
                    htmlFor="selectScene"
                    className="col-form-label col-1"
                >
                    Scene
                </label>
                <Dropdown id="selectScene" className='w-25'>
                    <Dropdown.Toggle split={!!test.sceneId}>
                        {test.sceneName ? `${test.sceneName} ` : 'Select a Scene '}
                    </Dropdown.Toggle>
                   <Dropdown.Menu variant='dark'>
                        {renderSceneOptions()}
                    </Dropdown.Menu> 
                </Dropdown>
            </div>
            <div style={{height: '470px'}}>
                <div style={{height: '425px', overflowY: 'scroll'}}>
                    {test.steps.length === 0 && <h4>Click the button to Add Actions</h4>}
                    {test.steps.length > 0 && renderActions()}
                </div>
                <div className="d-flex justify-content-end mt-3" style={{ marginRight: 160 }}>
                    <Button onClick={() => addAction()} variant="primary" size="lg">Add Action</Button>
                </div>
            </div>             
            <div className="d-flex justify-content-end" style={{ marginRight: 160, marginTop: 30 }}>
                <Button onClick={() => cancel()} variant="secondary" size="lg">Cancel</Button>
                <Button onClick={() => save()} style={{marginLeft: 9}} variant="primary" size="lg">Save</Button>                
            </div>
        </div>
    )
}

export default TestBuilder;