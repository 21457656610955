import React, { createContext, useState } from 'react';
import {RouterProvider} from "react-router-dom";
import router from "./router";
import Logout from "./components/Logout/Logout";
import { ToastContainer, Toast, ToastBody } from 'react-bootstrap';

export const ToastContext = createContext({
    setShowToast: null as unknown as React.Dispatch<React.SetStateAction<boolean>>,
    setToastMessage: null as unknown as React.Dispatch<React.SetStateAction<string>>,
    setToastType: null as unknown as React.Dispatch<React.SetStateAction<string>>,
  });

function App() {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    return (
        <div className="App">
            <ToastContainer
                className="position-stack"
                position="top-center"
            >
                <Toast
                    bg={toastType}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                >
                <ToastBody
                    className={toastType === 'success' ? 'text-white' : 'text-black'}
                >
                    {toastMessage}
                </ToastBody>
                </Toast>
            </ToastContainer>
            <div className="text-center">
                <div className="row logo-bar mb-3">
                    <Logout />
                </div>
            </div>           
            <div className='p-5'>
                <ToastContext.Provider
                    value={{setShowToast, setToastMessage, setToastType}}
                >
                    <RouterProvider router={router}/>
                </ToastContext.Provider>
            </div>
        </div>
    );
}

export default App;
