import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';
import {Button} from 'react-bootstrap';

function Login() {
  // Auth providers...
  const cognitoAuth = useCognitoAuth();

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Use case for when user must reset password...
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordResetUser, setPasswordResetUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const cognitoSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await cognitoAuth.signIn(username, password);
    if (result.success) {
      navigate({pathname: `${process.env.REACT_APP_LOGIN_SUCCESS_URI}`});
    } else if (result.resetPassword) {
      setPasswordResetUser(result.user);
      setPasswordReset(true);
    } else {
      alert('The username or password is invalid');
    }
  };

  const cognitoNewPasswordRequired = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (confirmPassword !== newPassword) {
      alert('New passwords do not match...');
      return;
    }
    const result = await cognitoAuth.newPassword(passwordResetUser, newPassword);
    if (result.success) {
      navigate({pathname: `${process.env.REACT_APP_LOGIN_SUCCESS_URI}`});
    } else {
      alert(result.message);
    }
  };

  const cancelCognitoPasswordReset = async () => {
    setPasswordReset(false);
  };

  return (
    <>
      {!passwordReset && (
        <div className="row col-12">
          <form noValidate onSubmit={cognitoSignIn}>
            <div className="row mb-3 justify-content-center">
              <div className="col-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-3 row justify-content-center">
              <Button type="submit" className="col-2" size="lg" variant="outline-primary">
                Login
              </Button>
            </div>
          </form>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
            <div>
              <img src="./Tupelo flow graphic (4).png" alt="" />
            </div>
          </div>
        </div>
      )}
      {passwordReset && (
      <div className="row col-12">
        <form noValidate onSubmit={cognitoNewPasswordRequired}>
          <h5>Admin New Password</h5>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                value={username}
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                className="form-control"
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                className="form-control"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <Button type="submit" size="lg" variant="outline-secondary">
                Submit
              </Button>
              &nbsp;&nbsp;
              <Button size="lg" variant="outline-danger" onClick={cancelCognitoPasswordReset}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
      )}

    </>
  );
}

export default Login;
