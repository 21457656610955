import {useRef, useEffect, useState, ReactNode} from 'react';
import * as d3 from 'd3';
import SentimentLog from '../Reports/SentimentLog';
import SentimentValue from '../Reports/SentimentValues';
import * as ReportUtils from '../../helpers/ReportUtils';
import { capitalize } from 'lodash';


type SentimentDetailsProps = {
  sentimentLogs: SentimentLog[];
}

type SentimentCount = {
  value: SentimentValue;
  count: number;
}

function SentimentDetails(props: SentimentDetailsProps) {
  const d3Container = useRef<SVGSVGElement>(null);
  const labelColor = d3.rgb(200, 200, 200).toString();

  const dimensions = {
    height: 120,
    width: 300,
    padding: {
      top: 0,
      right: 30,
      bottom: 0,
      left: 0,
    },
  };

  const getSentimentCounts = (sentimentLogs: SentimentLog[]): ["NEGATIVE" | "NEUTRAL" | "POSITIVE", number][]=> {
    const sentimentCounts = d3.flatRollup(sentimentLogs, v => v.length, d => d.sentiment.value);

    console.log('sentimentCounts', sentimentCounts);
    return sentimentCounts;
  }

  const update = () => {
    if (!props.sentimentLogs) {
      return
    }

    const sentimentSvg = d3.select('#sentiment-svg');
    
    const sentimentCounts = getSentimentCounts(props.sentimentLogs);
    const maxCount = d3.max(sentimentCounts, (d) => d[1]) || 10;

    const plotWidth = dimensions.width - dimensions.padding.left - dimensions.padding.right;

    const rowHeight = 40;
    const xScale = d3.scaleLinear()
      .domain([0, maxCount])
      .range([dimensions.padding.left, dimensions.padding.left + plotWidth]);

    sentimentSvg.selectAll('.sentiment-row').remove()

    const sentimentRowEnter = sentimentSvg.selectAll('.sentiment-row')
      .data(sentimentCounts)
      .enter();

    const sentimentRowG = sentimentRowEnter.append('g')
      .classed('sentiment-row', true)
      .attr('transform', (d, i) => `translate(${dimensions.padding.left}, ${i * rowHeight})`);

    sentimentRowG.append('rect')
      .attr('x', 0)
      .attr('y', rowHeight / 3 + 5)
      .attr('height', 5)
      .attr('width', (d) => xScale(d[1]))
      .style('fill', (d) => ReportUtils.getSentenceColor(d[0]))

    sentimentRowG.append('text')
      .attr('x', 0)
      .attr('y', rowHeight / 3)
      .style('fill', labelColor)
      .style("font-size", 16)
      .text((d) => capitalize(d[0]));

    sentimentRowG.append('text')
      .attr('x', plotWidth)
      .attr('y', rowHeight / 3)
      .style('fill', labelColor)
      .style("font-size", 16)
      .style('text-anchor', 'end')
      .text((d) => d[1]);
  }

  useEffect(update, [props]);
  return (
    <div className="sentiment-details">
      <label>Sentiment counts</label>  
      <svg 
        id="sentiment-svg" 
        ref={d3Container} 
        height={dimensions.height} 
        width={dimensions.width}
      />
    </div>
  )
}

export default SentimentDetails;