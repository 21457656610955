import {useRef, useEffect, ReactNode} from 'react';
import * as d3 from 'd3';
import ActionLog from '../Reports/ActionLog';
import * as ReportUtils from '../../helpers/ReportUtils';
import ActionType from '../../api/ActionType';
import SentimentLog from '../Reports/SentimentLog';
import Journey from '../../api/Journey';
import Test from '../../api/Test';
import { capitalize, filter } from 'lodash';
import SentimentValue from '../Reports/SentimentValues';
import SentimentDetails from '../SentimentDetails/SentimentDetails';
import SenitmentInspector from '../SentimentInspector/SentimentInspector';


type ReportDetailsProps = {
  test: Test,
  journey: Journey,
  actionLogs: ActionLog[],
  sentimentLogs: SentimentLog[],
  selectSentiment: (log?: SentimentLog) => any,
  selectedSentiment?: SentimentLog,
}

function ReportDetails(props: ReportDetailsProps) {
  const d3Container = useRef<SVGSVGElement>(null);

  const dimensions = {
    height: 500,
    width: 300,
    padding: {
      top: 30,
      right: 10,
      bottom: 10,
      left: 10,
    },
  };
  
  const borderColor = d3.rgb(150, 150, 150).toString();
  const labelColor = d3.rgb(200, 200, 200).toString();
  const rowHeight = 30;

  const initialize = () => {
    if (d3Container.current) {
      const svg = d3.select(`#${d3Container?.current.id}`);

      const width = d3Container?.current?.clientWidth || dimensions.width;
      
      const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
      const plotWidth = width - dimensions.padding.left - dimensions.padding.right;
      
      svg.append('rect')
        .attr('x', dimensions.padding.left)
        .attr('y', dimensions.padding.top)
        .attr('width', plotWidth)
        .attr('height', plotHeight)
        .style('stroke', borderColor)
        .style('fill', 'transparent');

      svg.append("text")
        .attr("x", dimensions.padding.left)
        .attr("y", dimensions.padding.top - 10)
        .style("fill", labelColor)
        .style("font-size", 24)
        .text("Legend")

      const actionsOffset = dimensions.padding.top + rowHeight + 5;

      svg.append("text")
        .attr("x", dimensions.padding.left + 10)
        .attr("y", dimensions.padding.top + rowHeight)
        .style("fill", labelColor)
        .style("font-size", 18)
        .text("Actions")

      const actionTypeValues = Object.values(ActionType);

      svg.selectAll('action-legend-row').remove();

      const actionLegendRow = svg.selectAll('action-legend-row')
        .data(filter(actionTypeValues, (val) => val !== ActionType.Comment))
        .enter();

      const actionG = actionLegendRow.append('g')
        .classed('action-legend-row', true)
        .attr('transform', (d, i) => `translate(${dimensions.padding.left}, ${actionsOffset + i * rowHeight})`)

      actionG.append('circle')
        .classed('action-legend-dot', true)
        .attr('cy', (rowHeight / 2))
        .attr('cx', 20)
        .attr('r', 5)
        .style('fill', (d) => ReportUtils.getColorByType(d));

      actionG.append('text')
        .attr("x", 40)
        .attr("y", rowHeight - 10)
        .style("fill", labelColor)
        .style("font-size", 16)
        .text((d) => capitalize(d));


      const sentimentOffset = dimensions.padding.top + ((actionTypeValues.length + 2) * rowHeight) + 5;

      svg.append("text")
        .attr("x", dimensions.padding.left + 10)
        .attr("y", sentimentOffset - 5)
        .style("fill", labelColor)
        .style("font-size", 18)
        .text("Sentiment")

      const sentimentTypes = Object.values(SentimentValue);

      svg.selectAll('sentiment-legend-row').remove();

      const sentimentLegendRow = svg.selectAll('sentiment-legend-row')
        .data(sentimentTypes)
        .enter();

      const sentimentG = sentimentLegendRow.append('g')
        .classed('sentiment-legend-row', true)
        .attr('transform', (d, i) => `translate(${dimensions.padding.left}, ${sentimentOffset + i * rowHeight})`)

      sentimentG.append('rect')
        .classed('sentiment-legend-rect', true)
        .attr('y', (rowHeight / 2) - 5)
        .attr('x', 15)
        .attr('height', 10)
        .attr('width', 10)
        .style('fill', (d) => ReportUtils.getSentenceColor(d))

      sentimentG.append('text')
        .attr("x", 40)
        .attr("y", rowHeight - 10)
        .style("fill", labelColor)
        .style("font-size", 16)
        .text((d) => capitalize(d))
    }
  }

  const updateSentiemnts = () => {

  }

  useEffect(initialize, []);
  useEffect(updateSentiemnts, [props]);

  const getClientName = () => {
    return <h4>Client: {props.test.clientName}</h4>
  }

  const getSceneName = () => {
    return <h4>Scene: {props.test.sceneName}</h4>
  }

  const getLogCount = () => {
    return <label>Log Count: {props.actionLogs.length}</label>
  }

  const getDuration = () => {
    const timeExtent = ReportUtils.getLogTimeExtent(props.actionLogs);
    const durationInSeconds = (timeExtent[1].getTime() - timeExtent[0].getTime()) / 1000;

    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;

    return <label>Duration: {minutes}:{seconds.toString().padStart(2, '0')}</label>
  }

  const getDetails = (): ReactNode | undefined => {
    let details;


    if (props.test) {
      details = (
        <>
          {getClientName()}
          {getSceneName()}
          {getLogCount()}
          {getDuration()}
        </>
      );
    }

    return details;
  }

  const getSentimentInspector = (): ReactNode | undefined => {
    let inspector;

    if (props.selectedSentiment) {
      inspector = <SenitmentInspector selectedSentiment={props.selectedSentiment} />
    }

    return inspector;
  }

  return (
    <div className="report-details">
      <div className="content">
        <div className="journey-details">
          {getDetails()}
        </div>
        <SentimentDetails sentimentLogs={props.sentimentLogs}/>
        {getSentimentInspector()}
      </div>
      <div className="legend">
        <svg 
          id="legend-svg" 
          ref={d3Container} 
          height={dimensions.height} 
          width={dimensions.width}
        />
      </div>
    </div>
  )
}

export default ReportDetails;