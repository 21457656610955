import {Auth} from 'aws-amplify';
import React, {createContext, useContext, useEffect, useState} from 'react';
import AwsConfigAuth from '../config/AwsConfigAuth';

Auth.configure({Auth: AwsConfigAuth});

interface UseCognitoAuth {
  isCognitoLoading: boolean;
  isCognitoAuthenticated: boolean;
  username: string;
  email: string;
  signIn: (username: string, password: string) => Promise<LoginResult>;
  newPassword: (user: any, password: string) => Promise<Result>;
  cognitoSignOut: () => void;
  federatedLogin: () => void;
}

interface Result {
  success: boolean;
  message: string;
}

interface LoginResult {
  success: boolean;
  message: string;
  resetPassword: boolean;
  user: any;
}

type Props = {
  children?: React.ReactNode;
};

const useCognitoAuthContext = createContext({} as UseCognitoAuth);

export const ProvideCognitoAuth: React.FC<Props> = ({children}) => {
  const auth = useProvideAuth();
  return <useCognitoAuthContext.Provider value={auth}>{children}</useCognitoAuthContext.Provider>;
};

export const useCognitoAuth = () => useContext(useCognitoAuthContext);

const useProvideAuth = (): UseCognitoAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUsername(result.username);
        setEmail(result.attributes.email);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUsername('');
        setEmail('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signIn = async (un: string, password: string) => {
    try {
      const result = await Auth.signIn(un, password);
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsAuthenticated(false);
        return {success: false,
          message: 'A new password is required before continuing.',
          resetPassword: true,
          user: result};
      }
      setUsername(result.username);
      setEmail(result.attributes.email);
      setIsAuthenticated(true);
      return {success: true, message: '', resetPassword: false, user: null};
    } catch (error) {
      const message = error as string;
      return {
        success: false,
        message,
        resetPassword: false,
        user: null,
      };
    }
  };

  const newPassword = async (user: any, password: string) => {
    try {
      const result = await Auth.completeNewPassword(user, password);
      setUsername(result.username);
      setEmail(result.attributes.email);
      setIsAuthenticated(true);
      return {success: true, message: '', resetPassword: false};
    } catch (error) {
      const message = error as string;
      return {
        success: false,
        message,
        resetPassword: false,
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername('');
      setEmail('');
      setIsAuthenticated(false);
      return {success: true, message: ''};
    } catch (error) {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  };

  const federatedLogin = async () => {
    try {
      setUsername('');
      setEmail('');
      setIsAuthenticated(false);
      const creds = await Auth.federatedSignIn({customProvider: 'cnx-login-idp'});
    } catch (error) {
      console.error(error);
    }
  };
  return {
    isCognitoLoading: isLoading,
    isCognitoAuthenticated: isAuthenticated,
    username,
    email,
    signIn,
    cognitoSignOut: signOut,
    newPassword,
    federatedLogin,
  };
};
