import {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import ActionLog from '../Reports/ActionLog';
import * as ReportUtils from '../../helpers/ReportUtils';
import ActionType from '../../api/ActionType';
import SentimentLog from '../Reports/SentimentLog';

type HeatMapProps = {
  actionLogs: ActionLog[];
  selectSentiment: (log?: SentimentLog) => any,
  selectedSentiment?: SentimentLog,
}

function HeatMap(props: HeatMapProps) {
  const d3Container = useRef(null);

  const dimensions = {
    height: 500,
    width: 1000,
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
  };

  type Scales = {
    x: d3.ScaleLinear<any, any>;
    y: d3.ScaleLinear<any, any>;
  }   

  const borderColor = d3.rgb(150, 150, 150).toString();
  const selectedColor = d3.rgb(255, 255, 255).toString();

  const [scales, setScales] = useState<Scales>();

  const dotRadius = 4;

  const initialize = () => {
    const svg = d3.select(d3Container.current);

    const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
    const plotWidth = dimensions.width - dimensions.padding.left - dimensions.padding.right;
    
    svg.append('rect')
      .attr('x', dimensions.padding.left)
      .attr('y', dimensions.padding.top)
      .attr('width', plotWidth)
      .attr('height', plotHeight)
      .style('stroke', borderColor)
      .style('fill', 'transparent');
  }

  const updateD3Parameters = () => {
    if (props.actionLogs) {
      const extent = ReportUtils.getLogExtent(props.actionLogs);

      const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
      const plotWidth = dimensions.width - dimensions.padding.left - dimensions.padding.right;

      const scaleX = d3.scaleLinear()
        .domain([extent.minX, extent.maxX])
        .range([dimensions.padding.left, dimensions.padding.left + plotWidth]);
      
      const scaleY = d3.scaleLinear()
        .domain([extent.maxY, extent.minY])
        .range([dimensions.padding.top, dimensions.padding.top + plotHeight]); 
      
      setScales({
        x: scaleX,
        y: scaleY,
      })
    }
  }

  const update = () => {
    if (scales) {
      const heatmap = d3.select('#tupelo-heat-map');
          
      updateEventsByType(heatmap, ActionType.Grab);
      updateEventsByType(heatmap, ActionType.Drop);
      updateEventsByType(heatmap, ActionType.Look);
      updateEventsByType(heatmap, ActionType.Movement);
      updateEventsByType(heatmap, ActionType.Find);
      updateEventsByType(heatmap, ActionType.Buy);
      updateComments(heatmap);
    }
  };

  const updateEventsByType = (heatmap: d3.Selection<any, any, any, any>, type: ActionType): void => {
    const events = ReportUtils.getEvents(props.actionLogs, type);

    heatmap.selectAll(`.${type}-dot`).remove();

    const eventEnter = heatmap.selectAll('.loc-dot')
      .data(events)
      .enter();

    eventEnter.append('circle')
      .classed(`.${type}-dot`, true)
      .attr('cx', (d) => scales?.x(d.x))
      .attr('cy', (d) => scales?.y(d.z))
      .attr('r', dotRadius)
      .style('fill', ReportUtils.getColorByType(type));
      
  }

  const updateComments = (heatmap: d3.Selection<any, any, any, any>) => {
    const events = ReportUtils.getEvents(props.actionLogs, ActionType.Comment);

    heatmap.selectAll('.sentiment-rect').remove();

    const sentimentLogsEnter = heatmap.selectAll('.sentiment-rect')
        .data(events)
        .enter();

      sentimentLogsEnter.append('rect')
        .classed('sentiment-rect', true)
        .attr('x', (d) => scales?.x(d.x))
        .attr('y', (d) => scales?.y(d.z))
        .attr('height', 10)
        .attr('width', 10)
        .style('fill', (d) => ReportUtils.getSentenceColor(d.sentimentLog?.sentiment.value))
        .style('stroke', (d) => {
          return d.id === props.selectedSentiment?.id ? selectedColor : ReportUtils.getSentenceColor(d.sentimentLog?.sentiment.value);
        })
        .style('stroke-width', 2)
        .on('click', (event, d) => {
          console.log("clicked: ", d);
          props.selectSentiment(d.sentimentLog);
        })
  }


  useEffect(initialize, []);
  useEffect(update, [scales]);
  useEffect(updateD3Parameters, [props]);

  return (
    <svg 
      id="tupelo-heat-map"
      height={dimensions.height}
      width={dimensions.width}
      ref={d3Container}
    /> 
  );
};

export default HeatMap;