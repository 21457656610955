import React from 'react';
import {Secure} from "../HOC/Secure";
import Dashboard from '../Dashboard/Dashboard';

function Main() {

    return <Dashboard />;
}

export default Secure(Main);
