import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';

import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import createTable from '../../helpers/table';
import Table from '../Table/Table';
import {Secure} from '../HOC/Secure';
import Client from '../../api/Client';
import apiClients from '../../api/apiClients';

function Clients() {
  const [clients, setClients] = useState([] as Client[]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<Client>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
    ],
    [],
  );

  useEffect(() => {
    getClients();
  }, []);

  function getClients() {
    apiClients.getClients().then((clients) => setClients(clients));
  }

  const table = createTable(columns, clients, setSorting, sorting);

  const getCellClass = (cellId: string) => {
    let className = '';
    if (cellId.indexOf('name') > -1) {
      className = 'text-start';
    }
    return className;
  };

  return (
    <>
      <div className="row justify-content-between">
      </div>
      <div className="row mt-3">
        {clients.length > 0 && <Table getCellClass={getCellClass} table={table} />}
        {clients.length === 0 && <h4>No clients yet</h4>}
      </div>
    </>
  );
}

export default Secure(Clients);
