import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';

import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import createTable from '../../helpers/table';
import Table from '../Table/Table';
import {Secure} from '../HOC/Secure';
import Test from '../../api/Test';
import apiTests from '../../api/apiTests';
import dayjs from 'dayjs';

function TestBuilder() {
  const [tests, setTests] = useState([] as Test[]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<Test>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: (cell) => <Link to={`/tests/${cell.row.original.id}`}>{cell.row.original.name}</Link>,
      },
      {
        accessorKey: 'sceneName',
        header: 'Scene',
      },
      {
        accessorKey: 'clientName',
        header: 'End Client',
      },
      {
        accessorKey: 'created',
        header: 'Date',
        accessorFn: (row) => dayjs(row.created).format('MMM D YYYY'),
      },
    ],
    [],
  );

  useEffect(() => {
    getTests();
  }, []);

  function getTests() {
    apiTests.getTests().then((tests) => setTests(tests));
  }

  const table = createTable(columns, tests, setSorting, sorting);

  const getCellClass = (cellId: string) => {
    let className = '';
    if (cellId.indexOf('id') > -1) {
      className = 'text-start';
    }
    return className;
  };

  return (
    <>
      <div className="row justify-content-between">
        <div className="col text-end">
          <Button
            variant="primary"
            href="/tests"
          >
            New Test
          </Button>
        </div>
      </div>
      <div className="row mt-2">
        {tests.length > 0 && <Table getCellClass={getCellClass} table={table} />}
        {tests.length === 0 && <h4>No tests yet</h4>}
      </div>
    </>
  );
}

export default Secure(TestBuilder);
