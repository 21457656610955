function setAlert(toastContext: any, alertStatus: string) {
    switch (alertStatus) {
      case 'success':
        toastContext.setToastMessage('Save successful.');
        toastContext.setToastType('success');
        toastContext.setShowToast(true);
        break;
      case 'failure':
        toastContext.setToastMessage('Save unsuccessful.');
        toastContext.setToastType('warning');
        toastContext.setShowToast(true);
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    setAlert
  };