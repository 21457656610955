import React from "react";
import SentimentLog from "../Reports/SentimentLog";
import { capitalize } from "lodash";

type SenitmentInspectorProps = {
  selectedSentiment?: SentimentLog;
}

function SenitmentInspector(props: SenitmentInspectorProps) {

  return (
    <div className="sentiment-inspector">
      <label>Sentiment Inspector</label>
      <label>{capitalize(props.selectedSentiment?.sentiment.value)}</label>
      <p className="sentiment-text">{props.selectedSentiment?.transcript}</p>
    </div>
  )
}

export default SenitmentInspector;