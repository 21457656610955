const AwsConfigAuth = {
  region: process.env.REACT_APP_COGNITO_AUTH_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cookieStorage: {
    domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE_DOMAIN,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: true,
  },
  authenticationFlowType: 'USER_SRP_AUTH',
  oauth: {
    domain: `${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}`,
    scope: ['email', 'openid'],
    redirectSignIn: `https://${process.env.REACT_APP_DOMAIN}/sso-post-login`,
    redirectSignOut: `https://${process.env.REACT_APP_DOMAIN}/login`,
    redirect: `https://${process.env.REACT_APP_DOMAIN}/login`,
    responseType: 'code',
  },
};

export default AwsConfigAuth;
