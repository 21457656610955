import * as d3 from 'd3';
import ActionLog from '../components/Reports/ActionLog';
import LogExtent from '../components/HeatMap/LogExtent';
import {filter} from 'lodash';
import ActionType from '../api/ActionType';
import SentimentValue from '../components/Reports/SentimentValues';

const movementColor = d3.rgb(0, 174, 239);
const grabColor = d3.rgb(0, 200, 5);
const releaseColor = d3.rgb(100, 80, 0);
const lookColor = d3.rgb(255, 240, 0);
const commentColor = d3.rgb(255, 0, 255);
const buyColor = d3.rgb(228,94,37);
const findColor = d3.rgb(255, 0, 255);

const positiveColor = d3.rgb(102, 106, 237);
const neutralColor = d3.rgb(182, 182, 191);
const negativeColor = d3.rgb(255, 60, 46);

const getLogExtent = (actionLogs: ActionLog[]): LogExtent => {
  const maxX = d3.max(actionLogs, (d) => d.x) || 0;
  const minX = d3.min(actionLogs, (d) => d.x) || 0;
  const maxY = d3.max(actionLogs, (d) => d.z) || 0;
  const minY = d3.min(actionLogs, (d) => d.z) || 0;
  
  return {maxX, minX, maxY, minY};
}

const getLogTimeExtent = (actionLogs: ActionLog[]): [Date, Date]=> {
  const extent = d3.extent(actionLogs, (d) => d.timestamp)
  return (extent[0] && extent[1]) ? extent : [new Date(), new Date()];
}

const getEvents = (actionLogs: ActionLog[], type: ActionType): ActionLog[] => {
  return filter(actionLogs, (actionLog) => actionLog.type === type);
}

const getColorByType = (type: ActionType): string => {
  let color = d3.rgb(0, 0, 0);

  if (type === ActionType.Movement) {
    color = movementColor;
  } else if (type === ActionType.Grab) {
    color = grabColor;
  } else if (type === ActionType.Drop) {
    color = releaseColor;
  } else if (type === ActionType.Look) {
    color = lookColor;
  } else if (type === ActionType.Comment) {
    color = commentColor;
  } else if (type === ActionType.Buy) {
    color = buyColor;
  } else if (type === ActionType.Find) {
    color = findColor;
  }

  return color.toString();
}

const getSentenceColor = (sentimentValue?: string): string => {
  let color;
  if (sentimentValue === SentimentValue.Negative) {
    color = negativeColor;
  } else if (sentimentValue === SentimentValue.Positive) {
    color = positiveColor;
  } else {
    color = neutralColor;
  }

  return color.toString();
}

export {
  getLogExtent,
  getLogTimeExtent,
  getEvents,
  getColorByType,
  getSentenceColor,
}