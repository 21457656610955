import {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import ActionLog from '../Reports/ActionLog';
import * as ReportUtils from '../../helpers/ReportUtils';
import ActionType from '../../api/ActionType';
import SentimentLog from '../Reports/SentimentLog';
import {filter} from 'lodash';

type TimelineProps = {
  actionLogs: ActionLog[],
  sentimentLogs: SentimentLog[],
  selectSentiment: (log?: SentimentLog) => any,
  selectedSentiment?: SentimentLog,
  
}

function Timeline(props: TimelineProps) {
  const d3Container = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(400);

  const dimensions = {
    height: 70,
    width: 400,
    padding: {
      top: 10,
      right: 30,
      bottom: 20,
      left: 30,
    },
  };

  type Scales = {
    x: d3.ScaleTime<any, any>;
  }   

  const borderColor = d3.rgb(150, 150, 150).toString();
  const selectedColor = d3.rgb(255, 255, 255).toString();

  const [scales, setScales] = useState<Scales>();

  const initialize = () => {
    if (d3Container.current) {
      const svg = d3.select(`#${d3Container?.current.id}`);

      const width = d3Container?.current?.clientWidth || dimensions.width;
      setContainerWidth(width);
      
      const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
      const plotWidth = width - dimensions.padding.left - dimensions.padding.right;
      
      svg.append('rect')
        .attr('x', dimensions.padding.left)
        .attr('y', dimensions.padding.top)
        .attr('width', plotWidth)
        .attr('height', plotHeight)
        .style('stroke', borderColor)
        .style('fill', 'transparent');
    }
  }

  const updateD3Parameters = () => {
    console.log("update parameters", props.sentimentLogs)
    
    if (props.actionLogs) {
      const plotWidth = containerWidth - dimensions.padding.left - dimensions.padding.right;

      const timeExtent = ReportUtils.getLogTimeExtent(props.actionLogs);

      const scaleX = d3.scaleTime()
        .domain(timeExtent)
        .range([dimensions.padding.left, dimensions.padding.left + plotWidth]); 

      setScales({
        x: scaleX,
      })
    }
  }

  const update = () => {
    if (scales) {
      const plotHeight = dimensions.height - dimensions.padding.top - dimensions.padding.bottom;
      const timeLine = d3.select('#tupelo-timeline');

      timeLine.selectAll(".event").remove();
      timeLine.selectAll(".sentiment-rect").remove();
      
      const eventLogsEnter = timeLine.selectAll('.event')
        .data(filter(props.actionLogs, (log) => log.type !== ActionType.Movement && log.type !== ActionType.Comment))
        .enter();

      eventLogsEnter.append('circle')
        .classed('event', true)
        .attr('cy', 40)
        .attr('cx', (d) => scales.x(d.timestamp))
        .attr('r', 5)
        .style('fill', (d) => ReportUtils.getColorByType(d.type))

      const sentimentLogsEnter = timeLine.selectAll('.sentiment-rect')
        .data(props.sentimentLogs)
        .enter();

      sentimentLogsEnter.append('rect')
        .classed('sentiment-rect', true)
        .attr("x", (d) => scales.x(d.created))
        .attr('y', 15)
        .attr('height', 10)
        .attr('width', 10)
        .style('fill', (d) => ReportUtils.getSentenceColor(d.sentiment.value))
        .style('stroke', (d) => {
          return d.id === props.selectedSentiment?.id ? selectedColor : ReportUtils.getSentenceColor(d.sentiment.value);
        })
        .style('stroke-width', 2)
        .on('click', (event, d) => {
          console.log("clicked: ", d);
          props.selectSentiment(d);
        })
    }
  };

  useEffect(initialize, []);
  useEffect(update, [scales]);
  useEffect(updateD3Parameters, [props]);

  return (
    <svg 
      id="tupelo-timeline"
      height={dimensions.height}
      ref={d3Container}
    /> 
  );
}

export default Timeline;