import React, {ComponentType} from 'react';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

export interface AdditionalProps {
  additionalProp: string;
}

/*
High Order Component for checking security.
Configured at each child component.
 */
export function Secure
  <P extends AdditionalProps>(WrappedComponent: ComponentType<P>): ComponentType<Omit<P, 'additionalProp'>> {
  const additionalProp = {};
  return (props) => (
    <PrivateRoute>
      <WrappedComponent
        additionalProp={additionalProp}
        {...props as any}
      />
    </PrivateRoute>
  );
}
