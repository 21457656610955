import React, { ReactNode, useContext, useEffect, useState } from 'react';
import Scene from '../../api/Scene';
import Test from '../../api/Test';
import {useNavigate} from 'react-router-dom';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { v4 } from 'uuid';
import apiJourneys from '../../api/apiJourneys';
import apiScenes from '../../api/apiScenes';
import apiTests from '../../api/apiTests';
import { ToastContext } from '../../App';
import toast from '../../helpers/toast';

function Journey() {
    const HOME_ROUTE = '/#/journeys';

    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const [availableScenes, setAvailableScenes] = useState<Scene[]>([] as Scene[]);
    const [availableTests, setAvailableTests] = useState<Test[]>([] as Test[]);
    const [email, setEmail] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    const [scheduled, setScheduled] = useState<string | null>(null);
    const [showErrorsModal, setShowErrorsModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [test, setTest] = useState<Test | null>(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => {
        apiTests.getTests().then((tests) => {
            setAvailableTests(tests);
        })

        apiScenes.getScenes().then((scenes) => {
            setAvailableScenes(scenes);
        });
    }, []);

    const renderSceneOptions = () => {
        return availableScenes.map((scene: Scene) => <Dropdown.Item key={scene.id} value={scene.id} onClick={() => updateScene(scene)}>{scene.name}</Dropdown.Item>)
    }

    const renderTestOptions = () => {
        return availableTests.map((test: Test) => <Dropdown.Item key={test.id} value={test.id} onClick={() => updateTest(test)}>{test.name}</Dropdown.Item>)
    }

    const save = () => {
        if (journeyErrors().length > 0 || !scene || !test || !name || !email || !scheduled) {
            setShowErrorsModal(true);
            return;
        }

        apiJourneys.createJourney({
            id: v4(),
            sceneId: scene.id,
            sceneName: scene.name,
            testId: test.id,
            testName: test.name,
            code: Math.floor(Math.random() * 100000),
            name,
            email,
            scheduled: new Date(scheduled)
        }).then(() => {
            toast.setAlert(toastContext, 'success');
            setTimeout(() => navigate(HOME_ROUTE), 1500);
        }).catch(() => toast.setAlert(toastContext, 'failure'));
    }

    const journeyErrors = (): string[] => {
        const errors = [];

        if (!email) {
            errors.push('Enter an email address');
        }

        if (!name) {
            errors.push('Name this journey');
        }

        if (!scheduled) {
            errors.push('Select a scheduled date');
        }

        if (scheduled && new Date(scheduled) < new Date()) {
            errors.push('Scheduled date must be after today');
        }

        if (!scene) {
            errors.push('Select a scene');
        }

        if (!test) {
            errors.push('Select a test');
        }

        return errors;
    }

    const errorsModal = (): ReactNode => (
        <Modal show={showErrorsModal} onHide={() => setShowErrorsModal(false)} style={{fontSize: 24}}>
          <Modal.Header closeButton>
            <Modal.Title>Journey Errors</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Correct the following errors:</div>
            <ul>
                {journeyErrors().map((error) => <li>{error}</li>)}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" size="lg" onClick={() => { setShowErrorsModal(false) }}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    const unsavedChangesModal = (): ReactNode => (
        <Modal show={showUnsavedChangesModal} onHide={() => setShowUnsavedChangesModal(false)} style={{fontSize: 24}}>
          <Modal.Header closeButton>
            <Modal.Title>You have unsaved changes to this journey.</Modal.Title>
          </Modal.Header>
          <Modal.Body>Save changes?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" size="lg" onClick={() => setShowUnsavedChangesModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" size="lg" onClick={() => cancel(true)}>
              No
            </Button>
            <Button variant="primary" size="lg" onClick={() => { setShowUnsavedChangesModal(false); save(); }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      );

    const cancel = (overrideUnsaved?: boolean) => {
        if (unsavedChanges && !overrideUnsaved) {
            setShowUnsavedChangesModal(true);
            return;
        }

        navigate(HOME_ROUTE);
    }

    const updateEmail = (email: string) => {
        setEmail(email);
        setUnsavedChanges(true);
    }

    const updateName = (name: string) => {
        setName(name);
        setUnsavedChanges(true);
    }

    const updateScene = (scene: Scene) => {
        setScene(scene);
        setUnsavedChanges(true);
    }

    const updateScheduled = (scheduled: string) => {
        setScheduled(scheduled);
        setUnsavedChanges(true);
    }

    const updateTest = (test: Test) => {
        setTest(test);
        setUnsavedChanges(true);
    }

    return (
        <div>
            {errorsModal()}
            {unsavedChangesModal()}
            <Button onClick={() => cancel()} variant="secondary" size="lg" className='back-button'>Back</Button>
            <div className="d-flex justify-content-between my-4">
                <h3>New Journey</h3>
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="txtName"
                    className="col-form-label col-1"
                    >
                    Name
                </label>
                <input
                    type="text"
                    className="form-control w-50"
                    id="txtName"
                    name="id"
                    value={name || ''}
                    onChange={(evt) => updateName(evt.currentTarget.value)}
                />
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="txtEmail"
                    className="col-form-label col-1"
                    >
                    Email
                </label>
                <input
                    type="text"
                    className="form-control w-50"
                    id="txtEmail"
                    name="email"
                    value={email || ''}
                    onChange={(evt) => updateEmail(evt.currentTarget.value)}
                />
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="selectScene"
                    className="col-form-label col-1"
                >
                    Scene
                </label>
                <Dropdown id="selectScene" className='w-25'>
                    <Dropdown.Toggle split={!!scene?.id}>
                        {scene?.name || 'Select a Scene'}
                    </Dropdown.Toggle>
                   <Dropdown.Menu variant='dark'>
                        {renderSceneOptions()}
                    </Dropdown.Menu> 
                </Dropdown>
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="selectTest"
                    className="col-form-label col-1"
                >
                    Test
                </label>
                <Dropdown id="selectTest" className='w-25'>
                    <Dropdown.Toggle split={!!test?.id}>
                        {test?.name || 'Select a Test'}
                    </Dropdown.Toggle>
                   <Dropdown.Menu variant='dark'>
                        {renderTestOptions()}
                    </Dropdown.Menu> 
                </Dropdown>
            </div>
            <div className="row mb-5">
                <label
                    htmlFor="scheduledDate"
                    className="col-form-label col-1"
                >
                    Scheduled Date
                </label>
                <input
                    type="date"
                    min={0}
                    className="form-control w-50"
                    id="scheduledDate"
                    name="expires"
                    value={scheduled || ''}
                    onChange={(evt) => updateScheduled(evt.currentTarget.value.split('T')[0])}
                />
            </div>
            <div className="d-flex justify-content-end">
                <Button onClick={() => cancel()} variant="secondary" size="lg">Cancel</Button>
                <Button onClick={() => save()} style={{marginLeft: 9}} variant="primary" size="lg">Save</Button>                
            </div>
        </div>
    )
}

export default Journey;