import React from 'react';

function Loading() {
  return (
    <div>
      Checking security please wait...
    </div>
  );
}

export default Loading;
