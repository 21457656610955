import React, {useEffect, useState} from 'react';
import {useCognitoAuth} from '../../hooks/useCognitoAuth';
import {Button} from 'react-bootstrap';

function Logout() {
    // Auth providers...
    const cognitoAuth = useCognitoAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        setIsAuthenticated(cognitoAuth.isCognitoAuthenticated);
    }, [cognitoAuth]);

    const logout = async () => {
        cognitoAuth.cognitoSignOut();
    };

    return (
        <>
            {isAuthenticated && (
                <div className="d-flex justify-content-between mt-1">
                    <div>
                        <img className="mx-auto" src="/tupelo_logo.png" alt="" height={72} width={180} />
                    </div>
                    <div>
                        {cognitoAuth.email}
                        <Button className="mx-3" size="sm" variant="outline-danger" onClick={logout}>
                            Logout
                        </Button>                    
                    </div>
                </div>)}
            {!isAuthenticated && (
                <div className="col">
                    <img className="mx-auto" src="/tupelo_logo.png" alt="" height={96} width={240} />
                </div>
            )}
        </>
    );
}

export default Logout;
