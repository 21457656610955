import {Secure} from '../HOC/Secure';
import {useNavigate, useParams} from 'react-router-dom';
import React, {ReactNode, useEffect, useState} from 'react';
import apiReports from '../../api/apiReports';
// import Client from "../../api/Client";
import './Reports.css';
import HeatMap from '../HeatMap/HeatMap.D3';
import Report from './Report';
import ActionLog from './ActionLog';
import Timeline from '../Timeline/Timeline.d3';
import Table from '../Table/Table';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import createTable from '../../helpers/table';
import { capitalize, round } from 'lodash';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import SentimentLog from './SentimentLog';
import ReportDetails from '../ReportDetails/ReportDetails';


function Reports() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [report, setReport] = useState<Report>({} as Report);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [selectedSentiment, setSelectedSentiment] = React.useState<SentimentLog | undefined>(undefined);

    useEffect(() => {
        if (id) {
            apiReports.getReport(id).then((report) => setReport(report));
        }
        console.log(id);
    }, []);

    const getActionLogTime = (log: ActionLog) => {
        const hour = log.timestamp.getHours().toString();
        const minute = log.timestamp.getMinutes().toString();
        return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`
    }

    const columns = React.useMemo<ColumnDef<ActionLog>[]>(
        () => [
          {
            accessorKey: 'type',
            header: 'Type',
            accessorFn: (row) => capitalize(row.type),
          },
          {
            accessorKey: 'objectName',
            header: 'Object',
          },
          {
            accessorKey: 'timestamp',
            header: 'Date',
            accessorFn: getActionLogTime,
          },
          {
            accessorKey: 'x',
            header: 'x',
            accessorFn: (row) => round(row.x, 3).toFixed(3),
          },
          {
            accessorKey: 'y',
            header: 'y',
            accessorFn: (row) => round(row.y, 3).toFixed(3),
          },
          {
            accessorKey: 'z',
            header: 'z',
            accessorFn: (row) => round(row.z, 3).toFixed(3),
          },
          
        ],
        [],
      );

    const table = createTable(columns, report.actionLogs, setSorting, sorting);

    const getCellClass = (cellId: string) => {
        let className = '';
        if (cellId.indexOf('id') > -1) {
        className = 'text-start';
        }
        return className;
    };

    const getTable = (): ReactNode => {
        let tableNode = (<h4>No Report Logs yet</h4>);
        if (report && report.actionLogs && report.actionLogs.length > 0) {
            tableNode = (<Table getCellClass={getCellClass} table={table} />);
        }

        return tableNode;
    }
    
    const selectSentimentLog = (log?: SentimentLog): void => {
        if (log && selectedSentiment) {
            if (selectedSentiment.id === log?.id) {
                setSelectedSentiment(undefined);
            } else {
                setSelectedSentiment(log);
            }
        } else {
            setSelectedSentiment(log)
        }
    }

    const getReportHeading = (): string => {
        return (report.test) ? `${report.test.name} Report` : 'Report';
    }

    const getReportDate = (): ReactNode | undefined => {
        let dateLabel;

        if (report.journey?.scheduled) {
            dateLabel = (<div className="header-report-date">- {dayjs(report.journey.scheduled).format('MMM D YYYY')}</div>)
        }

        return dateLabel
    }

    return (
        <><Button onClick={() => navigate('/#/journeys')} variant="secondary" size="lg" className='relative-back-button'>Back</Button><div id="main">
            <div id="header">
                <div className="header-label">{getReportHeading()}</div>
                {getReportDate()}
            </div>
            <div id="display">
                <div className="tupelo-display-row">
                    <div className="tupelo-container">
                        <h3 className="tupelo-container-header">Heat Map</h3>
                        <div id="tupelo-map-container" style={{
                            backgroundImage: `url("data:${report.scene?.scene_image_mime_type};base64, ${report.scene?.scene_image_base_64}")`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}>
                            
                            <HeatMap
                                actionLogs={report.actionLogs}
                                selectSentiment={(log) => selectSentimentLog(log)}
                                selectedSentiment={selectedSentiment} />
                        </div>
                    </div>
                    <div className="tupelo-container">
                        <h3 className="tupelo-container-header">Report Details</h3>
                        <ReportDetails
                            test={report.test}
                            journey={report.journey}
                            actionLogs={report.actionLogs}
                            sentimentLogs={report.sentimentLogs}
                            selectSentiment={(log) => selectSentimentLog(log)}
                            selectedSentiment={selectedSentiment} />
                    </div>
                </div>
                <div className="tupelo-timeline-container">
                    <h3 className="tupelo-container-header">Timeline</h3>
                    <div id="tupelo-controls">
                        <div className="tupelo-control-row">
                            <div className="tc-types"></div>
                        </div>
                    </div>
                    <Timeline
                        actionLogs={report.actionLogs}
                        sentimentLogs={report.sentimentLogs}
                        selectSentiment={(log) => selectSentimentLog(log)}
                        selectedSentiment={selectedSentiment} />
                </div>
                <div className="tupelo-display-row row mt-2">
                    <h3 className="tupelo-container-header">Report Logs</h3>
                    {getTable()}
                </div>
            </div>
        </div></>
    );
}

export default Secure(Reports);
