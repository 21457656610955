import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Tests from '../Tests/Tests';
import Audiences from '../Audiences/Audiences';
import Clients from '../Clients/Clients';
import { useLocation } from 'react-router-dom';
import './Dashboard.scss';

function Dashboard() {
  const location = useLocation();
  const keySplit = location.hash.split('#/');
  let defaultKey = 'home';

  if (keySplit.length === 2) {
    if (['home', 'clients', 'journeys'].includes(keySplit[1])) {
      defaultKey = keySplit[1];
    } 
  }

  return (
      <div className="d-none d-md-block" >
        <div className="row">
          <div className="col text-start">
          <Tabs
            defaultActiveKey={defaultKey}
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" title="Test Builder">
              <Tests />
            </Tab>
            <Tab eventKey="journeys" title="Audience Management">
              <Audiences />
            </Tab>
            <Tab eventKey="clients" title="Client Admin">
              <Clients />
            </Tab>
          </Tabs>
          </div>
        </div>
      </div>
  );
}

export default Dashboard;
