import tupeloApi from './helpers';
import Test from './Test';

const createTest = async (test: Test): Promise<Test> => {
  const result = await tupeloApi.post(`/test`, test);
  return result.data as Test;
};

const getTests = async (): Promise<Test[]> => {
  const result = await tupeloApi.get(`/tests/${process.env.REACT_APP_AGENCY_ID}`);
  return result.data as Test[];
};

const updateTest = async (test: Test): Promise<Test> => {
  const result = await tupeloApi.put(`/test`, test);
  return result.data as Test;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createTest,
  getTests,
  updateTest,
};
